.counter {
    display: flex;
    gap: 5px;

    &__item {
        gap: 2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

#promotionBanner,
#campaign-landing-page {
    .counter {
        display: flex;
        gap: 10px;

        @media (max-width: 991.98px) {
            gap: 5px;
        }

        &__item {
            flex-direction: column;
            width: 70px;
            height: 70px;
            border-radius: 3px;
            gap: unset;

            @media (max-width: 991.98px) {
                width: 30px;
                height: 30px;
            }
        }

        &__num {
            font-size: 36px;
            font-weight: 800;
            line-height: 1;
            white-space: nowrap;

            @media (max-width: 991.98px) {
                font-size: 12px;
            }
        }

        &__text {
            font-size: 16px;
            white-space: nowrap;
            text-transform: uppercase;

            @media (max-width: 991.98px) {
                font-size: 8px;
            }
        }
    }
}
