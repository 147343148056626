.countdown {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    background: var(--promotion-bar-bg-color, #fbf4eb);
    height: 100px;
    box-shadow: 0 2px 3px rgb(0 0 0 / 15%);
    z-index: 99;

    @media (max-width: 991.98px) {
        height: 44px;
    }

    &.countdown--only-mobile {

        @media (min-width: 991.98px) {
            display: none;
        }

        .countdown__inner {
            justify-content: space-between;
        }

        .countdown__logo {
            display: flex;
            opacity: 1;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        transition: opacity 400ms ease-in;
        opacity: 0;

        @media (max-width: 991.98px) {
            display: none;
        }

        img {
            max-width: 285px;

            @media (max-width: 991.98px) {
                max-height: 23px;
            }
        }
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        @media (max-width: 991.98px) {
            justify-content: center;
        }
    }

    &__counter {
        display: flex;
        align-items: center;
        gap: 15px;

        @media (max-width: 991.98px) {
            gap: 5px;
        }
    }

    &__counter-text {
        font-size: 24px;
        font-weight: 700;
        color: var(--promotion-bar-text-color);

        @media (max-width: 991.98px) {
            font-size: 14px;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        opacity: 0;
        transition: opacity 400ms ease-in;

        @media (max-width: 991.98px) {
            display: none;
        }

        .button {
            border-color: var(--cta-background-color, var(--primary-color));
            background-color: var(--cta-background-color, var(--primary-color));
            color: var(--cta-text-color, #fff);
            font-size: 16px;
            height: 44px;
            min-width: 160px;
            font-weight: 700;
            text-align: center;
            padding: 8px;

            &:hover {
                border-color: var(--cta-hover-background-color, var(--primary-color));
                background-color: var(--cta-hover-background-color, var(--primary-color));
                color: var(--cta-hover-text-color, #fff);
            }
        }
    }

    &.countdown--sticky {

        .countdown__inner {
            justify-content: space-between;
        }

        .countdown__logo {
            display: flex;
            opacity: 1;
        }

        .countdown__button {
            opacity: 1;
        }
    }

    &.countdown--deal {

        .countdown__inner {
            justify-content: space-between;
        }

        .countdown__logo {
            display: flex;
        }
    }
}
