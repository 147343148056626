.menu {
    background: var(--menu-bg-color);

    ul {
        display: flex;
        margin: 0;
        padding: 0;
        overflow-x: scroll;

        @include hide-scrollbar;

        li {
            flex-shrink: 0;
            list-style: none;
            border-right: 1px solid var(--menu-border-color);

            @include media-breakpoint-down(md) {
                width: 145px;
            }

            a {
                display: flex;
                height: 60px;
                align-items: center;
                padding: 0 21px;
                color: var(--menu-text-color);
                font-size: 16px;
                text-decoration: none;
                white-space: nowrap;

                @include media-breakpoint-down(lg) {
                    height: 38px;
                    font-size: 11px;
                }
            }

            &:last-child {

                @include media-breakpoint-down(lg) {
                    border-right: 0;
                    a {
                        padding-right: 0;
                    }
                }
            }

            &:first-child {
                border-left: 1px solid var(--menu-border-color);

                @include media-breakpoint-down(lg) {
                    border-left: 0;
                    a {
                        padding-left: 0;
                    }
                }
            }

            &:hover {
                background-color: var(--menu-bg-active-color);
            }

            &.active {
                background-color: var(--menu-bg-active-color);
            }
        }
    }
}
