.voucher-card {
    position: relative;
    display: flex;
    line-height: 1.3;
    color: var(--text-color);
    background-color: #FFF;

    &__image {
        min-width: 430px;

        @media only screen and (max-width: 1540px) {
            min-width: 330px;
        }

        @include media-breakpoint-down(md) {
            min-width: 100%;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__body {
        border: 2px solid var(--voucher-border-color);
        border-left: 0;
        padding: 30px 40px 20px;

        @include media-breakpoint-down(md) {
            border: 0;
        }

        @media only screen and (max-width: 480px) {
            padding: 20px;
        }
    }

    &__title {
        font-size: 22px;
        font-weight: 700;

        @media only screen and (max-width: 1540px) {
            font-size: 18px;
        }

        @media only screen and (max-width: 480px) {
            font-size: 18px;
        }
    }

    &__description {
        font-size: 16px;
        margin-top: 15px;

        @media only screen and (max-width: 480px) {
            font-size: 14px;
        }
    }

    button {
        margin-top: 25px;
        max-width: 200px;
        white-space: nowrap;

        i {
            position: relative;
            top: 2px;
        }

        @media only screen and (max-width: 1540px) {
            font-size: 16px;
            height: 38px;
            max-width: 150px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 18px;
        }
    }

    @include media-breakpoint-down(md) {
        flex-direction: column;
        border: 2px solid var(--voucher-border-color);
    }
}
