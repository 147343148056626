.newsletter {
    position: relative;
    background: var(--newsletter-bg-color);
    border: 1px solid var(--newsletter-border-color);
    padding: 65px 85px;
    color: var(--text-color);
    z-index: 1;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
        padding: 50px 30px;
    }

    &__title {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 50px;
        line-height: 1.2;

        @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 18px;
            text-align: center;
        }
    }

    p {

        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }

    ul {
        margin: 0;
        padding: 0 0 0 20px;

        li {
            list-style: square;

            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }

            &::marker {
                color: var(--newsletter-checked-bg-color);
            }
        }
    }

    .checkbox span {
        color: var(--text-color);
        font-size: 13px;
    }

    button {
        margin-top: 10px;
        max-width: 260px;

        @include media-breakpoint-down(sm) {
            margin: 10px auto 0;
            max-width: 180px;
        }
    }

    a {
        text-decoration: underline;
        font-weight: 600;
        color: var(--text-color);
    }

    &::after {
        display: block;
        position: absolute;
        left: -200px;
        top: 0;
        width: 920px;
        height: 100%;
        background: var(--newsletter-shape-bg-color);
        content: ' ';
        z-index: -1;
        transform: skewX(-30deg);

        @include media-breakpoint-down(xl) {
            width: 720px;
        }

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}
