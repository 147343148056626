.hero-carousel {

    .carousel-item {

        img {
            object-fit: cover;
            object-position: right;
            height: auto;
        }
    }

    .carousel-caption {
        max-width: 1520px;
        width: 100%;
        left: 50%;
        right: initial;
        top: 50%;
        bottom: initial;
        transform: translate(-50%, -50%);
        text-align: left;

        @include media-breakpoint-down(lg) {
            top: initial;
            left: 0;
            bottom: 20px;
            transform: none;
        }

        @include media-breakpoint-down(sm) {
            bottom: 0;
        }

        h1 {
            font-size: 44px;
            font-weight: 700;
            text-shadow: 3px 3px 5px rgba(0,0,0,.6);
            margin-left: 130px;
            color: var(--carousel-caption-color);

            @include media-breakpoint-down(lg) {
                margin-left: 20px;
                font-size: 32px;
                padding: 10px;
                background: rgb(0,0,0);
                background: linear-gradient(60deg, rgba(0,0,0,0.1) 15%, rgba(255,255,255,0) 100%);
            }

            @include media-breakpoint-down(md) {
                font-size: 24px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
        }
    }

    .carousel-control-prev, .carousel-control-next {
        width: 40px;
        height: 40px;
        top: 50%;
        transform: translateY(-50%);
        background: var(--carousel-control-background);
        border-radius: 50%;
        opacity: 1;
        color: var(--carousel-control-color);
    }

    .carousel-control-prev {
        left: 15px;
    }

    .carousel-control-next {
        right: 15px;
    }

    .carousel-indicators {
        gap: 0 10px;

        button {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #D9D9D9;
            border: 1px solid #474F51;
            opacity: 1;

            &.active {
                background: var(--carousel-active-indicator-background);
                border-color: var(--carousel-active-indicator-border);
            }
        }
    }
}
