.breadcrumb {
    position: relative;
    margin: 25px 0 45px;

    ul {
        display: flex;
        margin: 0;
        padding: 0;

        li {
            font-size: 12px;
            color: var(--text-color);
            list-style: none;

            a {
                color: var(--text-color);
            }

            &::after {
                display: inline;
                font-family: szguruicons;
                content: $szgicon-chevron-right;
                font-size: 10px;
                margin: 0 3px 0 2px;
            }

            &:last-child::after {
                display: none;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        display: none;
    }
}
