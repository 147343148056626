@import "swiper/swiper";
@import "swiper/modules/navigation";
@import "swiper/modules/pagination";
@import "swiper/modules/grid";

.swiper {
    padding: 10px 0;
}

.swiper-pagination-wrapper {
    padding-bottom: 40px;
}

.swiper img {
    display: none;
}

.swiper-out {
    position: relative;

    .swiper-button-prev, .swiper-button-next {
        transform: translateY(calc(-50% - 60px));
    }
}

.swiper-button-next, .swiper-button-prev {
    width: 58px;
    height: 58px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;

    &::after {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 58px;
        height: 58px;
        background: var(--swiper-pagination-bg-color);
        font-size: 16px;
        border: 1px solid var(--swiper-pagination-border-color);
        border-radius: 50%;
        color: var(--primary-color);
        font-weight: bold;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.swiper-button-next {
    right: -15px;
}

.swiper-button-prev {
    left: -25px;
}

.swiper-button-disabled {
    display: none;
}

.deal-list-swiper, .similar-offers-swiper, .campaign-list-swiper, .pp-packages-swiper, .foreign-deal-list-swiper {
    padding-right: 10px;

    @include media-breakpoint-down(md) {

        .swiper-slide {
            width: 70% !important;
        }
    }
}

.popular-destinations-swiper {

    .swiper-wrapper {
        gap: 50px;

        @include media-breakpoint-down(xxl) {
            gap: 20px;
        }

        @include media-breakpoint-down(lg) {
            gap: 0;
        }
    }

    .swiper-slide {
        flex: 0 1 calc(33% - 30px);

        @include media-breakpoint-down(xxl) {
            flex: 0 1 calc(33% - 11px);
        }

        @include media-breakpoint-down(lg) {
            flex: none;
        }

        @include media-breakpoint-down(md) {
            width: 40% !important;
        }
    }
}

.home-accommodations-swiper, .accommodation-presentation-swiper, .hotels-swiper {

    .swiper-slide {

        @include media-breakpoint-down(md) {
            width: 40% !important;
        }
    }
}

.home-accommodations-swiper {

    &-next, &-prev {
        top: calc(50% - 24px);
    }
}

.hotels-swiper {

    .swiper-slide {

        @include media-breakpoint-down(md) {
            width: 45% !important;
        }
    }
}
