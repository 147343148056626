.deal-list-box {
    position: relative;
    display: flex;
    background-color: #FFF;

    &__image-container {
        position: relative;
        width: 430px;
        height: 300px;
        flex: 0 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @media only screen and (width <= 1540px) {
            height: 225px;
            width: 330px;
        }

        @media only screen and (width <= 767px) {
            width: 100%;
            height: auto;
        }
    }

    &__images-count {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgb(0 0 0 / 60%);
        color: #fff;
        font-size: 16px;
        width: 60px;
        height: 40px;
        white-space: nowrap;

        &::after {
            position: absolute;
            display: block;
            left: -66%;
            top: 0;
            content: ' ';
            border-bottom: 40px solid rgb(0 0 0 / 60%);
            border-left: 40px solid transparent;
        }

        @media only screen and (width <= 767px) {
            display: none;
        }
    }

    &__description {
        flex: 1 1 auto;
        border: 2px solid var(--deal-list-box-border-color);
        border-left: 0;
        border-right: 0;
        padding: 35px 50px 20px;
        font-size: 16px;
        line-height: 1.3;

        @media only screen and (width <= 1540px) {
            padding: 20px;
            font-size: 14px;
        }

        @media only screen and (width <= 767px) {
            border: 0;
        }
    }

    &__cta {
        position: relative;
        width: 250px;
        flex: 0 0 auto;
        border: 2px solid var(--deal-list-box-border-color);
        border-left: 0;
        padding: 80px 25px 10px;
        text-align: right;

        &::after {
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 2px;
            height: 90%;
            transform: translateY(-50%);
            content: ' ';
            background: var(--deal-list-box-border-color);

            @media only screen and (width <= 767px) {
                display: none;
            }
        }

        @media only screen and (width <= 1540px) {
            width: 210px;
            padding-top: 45px;
        }

        @media only screen and (width <= 767px) {
            width: 100%;
            border: 0;
            background: var(--deal-list-box-cta-background);
            padding: 10px 20px;
        }
    }

    &__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 22px;
        font-weight: 700;
        color: var(--text-color);

        @media only screen and (width <= 1540px) {
            font-size: 14px;
        }

        @media only screen and (width <= 480px) {
            font-size: 14px;
        }
    }

    &__location {
        margin-top: 5px;

        @media only screen and (width <= 480px) {
            font-size: 12px;
        }
    }

    &__short-description {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: 15px;

        @media only screen and (width <= 1540px) {
            -webkit-line-clamp: 3;
        }

        @media only screen and (width <= 767px) {
            display: none;
        }
    }

    &__deadline {
        margin-top: 25px;

        strong {
            font-weight: 400;
        }

        @media only screen and (width <= 1540px) {
            margin-top: 15px;
        }

        @media only screen and (width <= 480px) {
            font-size: 12px;
        }
    }

    &__discount {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 8px;
        right: -10px;
        width: 60px;
        height: 60px;
        background: var(--deal-box-discount-background);
        color: var(--deal-box-discount-text-color);
        font-size: 22px;
        font-weight: 700;
        z-index: 1;
        white-space: nowrap;
        padding-right: 10px;

        &::after {
            position: absolute;
            display: block;
            left: -12px;
            top: 0;
            width: 30px;
            height: 60px;
            content: ' ';
            z-index: -1;
            background: var(--deal-box-discount-background);
            transform: skewX(16deg);
        }

        @media only screen and (width <= 1540px) {
            top: 4px;
            height: 40px;
            width: 40px;
            font-size: 16px;

            &::after {
                height: 40px;
            }
        }

        @media only screen and (width <= 480px) {
            width: 50px;
            height: 50px;
            font-size: 19px;

            &::after {
                height: 50px;
            }
        }
    }

    &__badge {
        display: inline-block;
        font-size: 14px;
        color: var(--deal-box-badge-color);
        background: var(--deal-box-badge-background);
        padding: 2px 8px;
        margin-bottom: 8px;
        white-space: nowrap;

        @media only screen and (width <= 1540px) {
            font-size: 12px;
        }

        @media only screen and (width <= 767px) {
            display: none;
        }
    }

    &__prices {
        display: flex;
        flex-direction: column;
        line-height: 1.1;
        white-space: nowrap;

        @media only screen and (width <= 767px) {
            flex-direction: row-reverse;
            align-items: flex-end;
            gap: 0 10px;
            margin-bottom: 5px;
        }
    }

    &__original-price {
        text-decoration: line-through;
        color: var(--deal-list-box-original-price-color);
        font-size: 18px;

        @media only screen and (width <= 1540px) {
            font-size: 14px;
        }

        @media only screen and (width <= 480px) {
            font-size: 12px;
        }
    }

    &__price {
        font-size: 30px;
        font-weight: 900;
        color: var(--promotion-price-color, var(--deal-list-box-price-color));

        @media only screen and (width <= 1540px) {
            font-size: 22px;
        }

        @media only screen and (width <= 480px) {
            font-size: 20px;
        }
    }

    &__catering {
        font-size: 16px;
        margin-bottom: 10px;

        @media only screen and (width <= 1540px) {
            font-size: 14px;
        }

        @media only screen and (width <= 767px) {
            margin-bottom: 0;
        }

        @media only screen and (width <= 480px) {
            font-size: 12px;
        }
    }

    &__tag {
        font-size: 14px;
        text-align: center;
        color: var(--deal-list-box-tag-color);
        margin-bottom: 5px;

        @media only screen and (width <= 1540px) {
            font-size: 12px;
        }

        @media only screen and (width <= 767px) {
            display: none;
        }
    }

    .button--primary-outline {

        i {
            position: relative;
            top: 2px;
        }

        @media only screen and (width <= 1540px) {
            font-size: 16px;
            height: 38px;
        }

        @media only screen and (width <= 767px) {
            display: none;
        }
    }

    @media only screen and (width <= 767px) {
        flex-direction: column;
        border: 2px solid var(--deal-list-box-border-color);
    }
}
