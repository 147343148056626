@font-face {
    font-family: 'Noto Sans';
    src: url('../../fonts/NotoSans-VariableFont_wdth,wght.eot') format('embedded-opentype'),
         url('../../fonts/NotoSans-VariableFont_wdth,wght.woff2') format('woff2'),
         url('../../fonts/NotoSans-VariableFont_wdth,wght.ttf') format('truetype');
    font-style: normal;
}

:root {
    // Fonts
    --bs-body-font-family: "Noto Sans", sans-serif;

    // Header
    --header-top-bg: #fff;
    --header-search-border: #afafaf;

    // Global
    --primary-color: #f60;
    --text-color: #222;
    --link-color: #3598db;

    // Menu
    --menu-bg-color: #bf392a;
    --menu-text-color: #fff;
    --menu-border-color: #fff;
    --menu-bg-active-color: #d95f50;

    // Deal box
    --deal-box-border-color: #efefef;
    --deal-box-badge-text-color: #fff;
    --deal-box-badge-blue: #3598db;
    --deal-box-badge-red: #bf392a;
    --deal-box-discount-background: var(--primary-color);
    --deal-box-discount-text-color: #fff;
    --deal-box-cta-bg-color: #f6f6f6;

    // Footer
    --footer-bg-color: #f6f6f6;
    --footer-copyright-bg-color: #eee;
    --footer-social-bg-color: #dad4c9;

    // Swiper
    --swiper-pagination-bg-color: #f6f6f6;
    --swiper-pagination-border-color: #d0d0d0;
    --swiper-navigation-size: 58px;

    // Destination card
    --destination-card-title-bg-color: rgba(255, 255, 255, 0.8);

    // Newsletter
    --newsletter-bg-color: #f6f6f6;
    --newsletter-border-color: #f0f0f0;
    --newsletter-checked-bg-color: var(--primary-color);
    --newsletter-shape-bg-color: #d9d9d9;

    // Inputs
    --input-default-bg-color: #fff;
    --input-default-border-color: #cacaca;
    --input-default-focus-border: #3598db;

    // Checkboxes
    --checkbox-border-color: #d0d0d0;
    --checkbox-bg-color: #fff;
    --checkbox-checked-bg-color: var(--primary-color);
    --checkbox-checked-icon-color: #fff;

    // Buttons
    --button-primary-bg-color: var(--primary-color);
    --button-primary-color: #fff;
    --button-primary-outline-color: var(--primary-color);

    // Badges
    --badge-filter-background: #eeeeee;
    --badge-filter-color: #818181;
    --badge-filter-link-color: var(--primary-color);

    // Filter Sidebar
    --filter-sidebar-border-color: #d9d9d9;
    --filter-sidebar-seperate-border-color: #d0cabd;
    --filter-sidebar-count-color: #9b9b9b;

    // Deal List Box
    --deal-list-box-border-color: #dcdcdc;
    --deal-list-box-cta-background: #f6f6f6;
    --deal-box-discount-color: #fff;
    --deal-box-badge-color: #fff;
    --deal-box-badge-background: #3598db;
    --deal-list-box-original-price-color: var(--primary-color);
    --deal-list-box-price-color: var(--primary-color);
    --deal-list-box-tag-color: #bf3a2a;

    // Voucher
    --voucher-border-color: #dcdcdc;

    // HR tag
    --hr-color: #d9d9d9;

    // Calendar
    --calendar-th-background: #dadada;
    --calendar-available-color: #5dd179;
    --calendar-available-text-color: #fff;
    --calendar-non-available-color: #f75a5a;
    --calendar-non-available-text-color: #fff;
    --calendar-not-actual-color: #f3f3f3;
    --calendar-not-actual-text-color: #d1d1d1;

    // Deal Page
    --deal-page-bulletpoint-color: var(--primary-color);

    // Deal Order Box (Deal Page)
    --deal-order-box-border-color: #d9d9d9;
    --deal-order-box-discount-background: var(--primary-color);
    --deal-order-box-price-color: var(--primary-color);
    --deal-order-box-tag-color: #bf3a2a;
    --deal-order-box-bottom-background: #f6f6f6;

    // Carousel
    --carousel-caption-color: #fff;
    --carousel-control-background: #D9D9D9;
    --carousel-control-color: #292929;
    --carousel-active-indicator-background: #B93825;
    --carousel-active-indicator-border: #fff;

    // Toast
    --bs-success: #218C3A;
    --bs-success-rgb: 33, 140, 58;
}
