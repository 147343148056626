@import "./variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?6sagii');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?6sagii#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?6sagii') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?6sagii') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?6sagii##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i.szgicon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.szgicon--circle {
    border: 1px solid var(--primary-color);
    border-radius: 50%;
    padding: 2px;
}

.szgicon-call_center {
  &:before {
    content: $szgicon-call_center;
  }
}
.szgicon-airplane-icon {
  &:before {
    content: $szgicon-airplane-icon;
  }
}
.szgicon-accessibility {
  &:before {
    content: $szgicon-accessibility;
  }
}
.szgicon-acclimatisation {
  &:before {
    content: $szgicon-acclimatisation;
  }
}
.szgicon-alert2 {
  &:before {
    content: $szgicon-alert2;
  }
}
.szgicon-animalfriendly {
  &:before {
    content: $szgicon-animalfriendly;
  }
}
.szgicon-apple {
  &:before {
    content: $szgicon-apple;
  }
}
.szgicon-app-promo {
  &:before {
    content: $szgicon-app-promo;
  }
}
.szgicon-arrow-down {
  &:before {
    content: $szgicon-arrow-down;
  }
}
.szgicon-arrow-left {
  &:before {
    content: $szgicon-arrow-left;
  }
}
.szgicon-arrow-right {
  &:before {
    content: $szgicon-arrow-right;
  }
}
.szgicon-arrow-right-tall {
  &:before {
    content: $szgicon-arrow-right-tall;
  }
}
.szgicon-arrow-striped-right {
  &:before {
    content: $szgicon-arrow-striped-right;
  }
}
.szgicon-arrow-up {
  &:before {
    content: $szgicon-arrow-up;
  }
}
.szgicon-articles {
  &:before {
    content: $szgicon-articles;
  }
}
.szgicon-available {
  &:before {
    content: $szgicon-available;
  }
}
.szgicon-baby-bed {
  &:before {
    content: $szgicon-baby-bed;
  }
}
.szgicon-babyfriendly {
  &:before {
    content: $szgicon-babyfriendly;
  }
}
.szgicon-baby-tub {
  &:before {
    content: $szgicon-baby-tub;
  }
}
.szgicon-badge-controlled {
  &:before {
    content: $szgicon-badge-controlled;
  }
}
.szgicon-balcony {
  &:before {
    content: $szgicon-balcony;
  }
}
.szgicon-bank-note {
  &:before {
    content: $szgicon-bank-note;
  }
}
.szgicon-basement {
  &:before {
    content: $szgicon-basement;
  }
}
.szgicon-bath {
  &:before {
    content: $szgicon-bath;
  }
}
.szgicon-bathrobe {
  &:before {
    content: $szgicon-bathrobe;
  }
}
.szgicon-beach {
  &:before {
    content: $szgicon-beach;
  }
}
.szgicon-beds-number {
  &:before {
    content: $szgicon-beds-number;
  }
}
.szgicon-bed-type-armchairbed {
  &:before {
    content: $szgicon-bed-type-armchairbed;
  }
}
.szgicon-bed-type-baldachin {
  &:before {
    content: $szgicon-bed-type-baldachin;
  }
}
.szgicon-bed-type-bunkbed {
  &:before {
    content: $szgicon-bed-type-bunkbed;
  }
}
.szgicon-bed-type-doublebed {
  &:before {
    content: $szgicon-bed-type-doublebed;
  }
}
.szgicon-bed-type-doubleseparatedbed {
  &:before {
    content: $szgicon-bed-type-doubleseparatedbed;
  }
}
.szgicon-bed-type-extrabed {
  &:before {
    content: $szgicon-bed-type-extrabed;
  }
}
.szgicon-bed-type-kingsizebed {
  &:before {
    content: $szgicon-bed-type-kingsizebed;
  }
}
.szgicon-bed-type-singlebed {
  &:before {
    content: $szgicon-bed-type-singlebed;
  }
}
.szgicon-bed-type-sofabed {
  &:before {
    content: $szgicon-bed-type-sofabed;
  }
}
.szgicon-bicycle {
  &:before {
    content: $szgicon-bicycle;
  }
}
.szgicon-blind {
  &:before {
    content: $szgicon-blind;
  }
}
.szgicon-blog {
  &:before {
    content: $szgicon-blog;
  }
}
.szgicon-boattrip {
  &:before {
    content: $szgicon-boattrip;
  }
}
.szgicon-bolt {
  &:before {
    content: $szgicon-bolt;
  }
}
.szgicon-broken-heart {
  &:before {
    content: $szgicon-broken-heart;
  }
}
.szgicon-building {
  &:before {
    content: $szgicon-building;
  }
}
.szgicon-bulb {
  &:before {
    content: $szgicon-bulb;
  }
}
.szgicon-calendar {
  &:before {
    content: $szgicon-calendar;
  }
}
.szgicon-calm {
  &:before {
    content: $szgicon-calm;
  }
}
.szgicon-camera {
  &:before {
    content: $szgicon-camera;
  }
}
.szgicon-car {
  &:before {
    content: $szgicon-car;
  }
}
.szgicon-caret-down {
  &:before {
    content: $szgicon-caret-down;
  }
}
.szgicon-caret-left {
  &:before {
    content: $szgicon-caret-left;
  }
}
.szgicon-caret-right {
  &:before {
    content: $szgicon-caret-right;
  }
}
.szgicon-caret-up {
  &:before {
    content: $szgicon-caret-up;
  }
}
.szgicon-category {
  &:before {
    content: $szgicon-category;
  }
}
.szgicon-ceiling-fan {
  &:before {
    content: $szgicon-ceiling-fan;
  }
}
.szgicon-celebrate {
  &:before {
    content: $szgicon-celebrate;
  }
}
.szgicon-change {
  &:before {
    content: $szgicon-change;
  }
}
.szgicon-chat {
  &:before {
    content: $szgicon-chat;
  }
}
.szgicon-checkmark {
  &:before {
    content: $szgicon-checkmark;
  }
}
.szgicon-chevron-down {
  &:before {
    content: $szgicon-chevron-down;
  }
}
.szgicon-chevron-down2 {
  &:before {
    content: $szgicon-chevron-down2;
  }
}
.szgicon-chevron-left {
  &:before {
    content: $szgicon-chevron-left;
  }
}
.szgicon-chevron-right {
  &:before {
    content: $szgicon-chevron-right;
  }
}
.szgicon-chevron-up {
  &:before {
    content: $szgicon-chevron-up;
  }
}
.szgicon-chevron-up2 {
  &:before {
    content: $szgicon-chevron-up2;
  }
}
.szgicon-child {
  &:before {
    content: $szgicon-child;
  }
}
.szgicon-christmas {
  &:before {
    content: $szgicon-christmas;
  }
}
.szgicon-circle {
  &:before {
    content: $szgicon-circle;
  }
}
.szgicon-citycenter {
  &:before {
    content: $szgicon-citycenter;
  }
}
.szgicon-clover {
  &:before {
    content: $szgicon-clover;
  }
}
.szgicon-clueless {
  &:before {
    content: $szgicon-clueless;
  }
}
.szgicon-coach {
  &:before {
    content: $szgicon-coach;
  }
}
.szgicon-coctail {
  &:before {
    content: $szgicon-coctail;
  }
}
.szgicon-coins {
  &:before {
    content: $szgicon-coins;
  }
}
.szgicon-column {
  &:before {
    content: $szgicon-column;
  }
}
.szgicon-comment-line {
  &:before {
    content: $szgicon-comment-line;
  }
}
.szgicon-common-bath {
  &:before {
    content: $szgicon-common-bath;
  }
}
.szgicon-common-kitchen {
  &:before {
    content: $szgicon-common-kitchen;
  }
}
.szgicon-congress-center {
  &:before {
    content: $szgicon-congress-center;
  }
}
.szgicon-copy {
  &:before {
    content: $szgicon-copy;
  }
}
.szgicon-credit-card {
  &:before {
    content: $szgicon-credit-card;
  }
}
.szgicon-credit-card-cvv {
  &:before {
    content: $szgicon-credit-card-cvv;
  }
}
.szgicon-cursor-telekom {
  &:before {
    content: $szgicon-cursor-telekom;
  }
}
.szgicon-daily-menu {
  &:before {
    content: $szgicon-daily-menu;
  }
}
.szgicon-date-range {
  &:before {
    content: $szgicon-date-range;
  }
}
.szgicon-deaf {
  &:before {
    content: $szgicon-deaf;
  }
}
.szgicon-deck {
  &:before {
    content: $szgicon-deck;
  }
}
.szgicon-delete {
  &:before {
    content: $szgicon-delete;
  }
}
.szgicon-denied {
  &:before {
    content: $szgicon-denied;
  }
}
.szgicon-desk {
  &:before {
    content: $szgicon-desk;
  }
}
.szgicon-discount {
  &:before {
    content: $szgicon-discount;
  }
}
.szgicon-dishwash {
  &:before {
    content: $szgicon-dishwash;
  }
}
.szgicon-dishwasher-machine {
  &:before {
    content: $szgicon-dishwasher-machine;
  }
}
.szgicon-document-tables {
  &:before {
    content: $szgicon-document-tables;
  }
}
.szgicon-download {
  &:before {
    content: $szgicon-download;
  }
}
.szgicon-edit-line {
  &:before {
    content: $szgicon-edit-line;
  }
}
.szgicon-enlarge {
  &:before {
    content: $szgicon-enlarge;
  }
}
.szgicon-envelope {
  &:before {
    content: $szgicon-envelope;
  }
}
.szgicon-envelope-fast {
  &:before {
    content: $szgicon-envelope-fast;
  }
}
.szgicon-event-busy {
  &:before {
    content: $szgicon-event-busy;
  }
}
.szgicon-excellent-hotel {
  &:before {
    content: $szgicon-excellent-hotel;
  }
}
.szgicon-exclamation-sign {
  &:before {
    content: $szgicon-exclamation-sign;
  }
}
.szgicon-eye {
  &:before {
    content: $szgicon-eye;
  }
}
.szgicon-facebook-messenger {
  &:before {
    content: $szgicon-facebook-messenger;
  }
}
.szgicon-family-with-kid {
  &:before {
    content: $szgicon-family-with-kid;
  }
}
.szgicon-fast-answer {
  &:before {
    content: $szgicon-fast-answer;
  }
}
.szgicon-fb {
  &:before {
    content: $szgicon-fb;
  }
}
.szgicon-feedback-24h {
  &:before {
    content: $szgicon-feedback-24h;
  }
}
.szgicon-feedback-bad {
  &:before {
    content: $szgicon-feedback-bad;
  }
}
.szgicon-feedback-good {
  &:before {
    content: $szgicon-feedback-good;
  }
}
.szgicon-feedback-great {
  &:before {
    content: $szgicon-feedback-great;
  }
}
.szgicon-feedback-ok {
  &:before {
    content: $szgicon-feedback-ok;
  }
}
.szgicon-feedback-poor {
  &:before {
    content: $szgicon-feedback-poor;
  }
}
.szgicon-feedback-wonderful {
  &:before {
    content: $szgicon-feedback-wonderful;
  }
}
.szgicon-file {
  &:before {
    content: $szgicon-file;
  }
}
.szgicon-file-image {
  &:before {
    content: $szgicon-file-image;
  }
}
.szgicon-file-pdf {
  &:before {
    content: $szgicon-file-pdf;
  }
}
.szgicon-filter {
  &:before {
    content: $szgicon-filter;
  }
}
.szgicon-fireplace {
  &:before {
    content: $szgicon-fireplace;
  }
}
.szgicon-flag {
  &:before {
    content: $szgicon-flag;
  }
}
.szgicon-flatscreen {
  &:before {
    content: $szgicon-flatscreen;
  }
}
.szgicon-floor {
  &:before {
    content: $szgicon-floor;
  }
}
.szgicon-fullscreen {
  &:before {
    content: $szgicon-fullscreen;
  }
}
.szgicon-fullscreen-exit {
  &:before {
    content: $szgicon-fullscreen-exit;
  }
}
.szgicon-garden {
  &:before {
    content: $szgicon-garden;
  }
}
.szgicon-gas-stove {
  &:before {
    content: $szgicon-gas-stove;
  }
}
.szgicon-getupper {
  &:before {
    content: $szgicon-getupper;
  }
}
.szgicon-gift {
  &:before {
    content: $szgicon-gift;
  }
}
.szgicon-gmail {
  &:before {
    content: $szgicon-gmail;
  }
}
.szgicon-grill {
  &:before {
    content: $szgicon-grill;
  }
}
.szgicon-ground-floor {
  &:before {
    content: $szgicon-ground-floor;
  }
}
.szgicon-group {
  &:before {
    content: $szgicon-group;
  }
}
.szgicon-group-2 {
  &:before {
    content: $szgicon-group-2;
  }
}
.szgicon-guided-tours {
  &:before {
    content: $szgicon-guided-tours;
  }
}
.szgicon-hairdryer {
  &:before {
    content: $szgicon-hairdryer;
  }
}
.szgicon-hand-circled {
  &:before {
    content: $szgicon-hand-circled;
  }
}
.szgicon-handdraw-arrow-down {
  &:before {
    content: $szgicon-handdraw-arrow-down;
  }
}
.szgicon-hard-of-hearing {
  &:before {
    content: $szgicon-hard-of-hearing;
  }
}
.szgicon-headset {
  &:before {
    content: $szgicon-headset;
  }
}
.szgicon-heart-border {
  &:before {
    content: $szgicon-heart-border;
  }
}
.szgicon-heart-full {
  &:before {
    content: $szgicon-heart-full;
  }
}
.szgicon-highway {
  &:before {
    content: $szgicon-highway;
  }
}
.szgicon-hm {
  &:before {
    content: $szgicon-hm;
  }
}
.szgicon-hospital {
  &:before {
    content: $szgicon-hospital;
  }
}
.szgicon-hotel-bell {
  &:before {
    content: $szgicon-hotel-bell;
  }
}
.szgicon-hotel-house {
  &:before {
    content: $szgicon-hotel-house;
  }
}
.szgicon-hotel-offer {
  &:before {
    content: $szgicon-hotel-offer;
  }
}
.szgicon-hourglass-bottom {
  &:before {
    content: $szgicon-hourglass-bottom;
  }
}
.szgicon-info {
  &:before {
    content: $szgicon-info;
  }
}
.szgicon-information {
  &:before {
    content: $szgicon-information;
  }
}
.szgicon-instagram {
  &:before {
    content: $szgicon-instagram;
  }
}
.szgicon-iron {
  &:before {
    content: $szgicon-iron;
  }
}
.szgicon-jacuzzi {
  &:before {
    content: $szgicon-jacuzzi;
  }
}
.szgicon-key {
  &:before {
    content: $szgicon-key;
  }
}
.szgicon-label {
  &:before {
    content: $szgicon-label;
  }
}
.szgicon-license {
  &:before {
    content: $szgicon-license;
  }
}
.szgicon-list-add {
  &:before {
    content: $szgicon-list-add;
  }
}
.szgicon-list-checked {
  &:before {
    content: $szgicon-list-checked;
  }
}
.szgicon-list-view {
  &:before {
    content: $szgicon-list-view;
  }
}
.szgicon-loading {
  &:before {
    content: $szgicon-loading;
  }
}
.szgicon-location {
  &:before {
    content: $szgicon-location;
  }
}
.szgicon-lock {
  &:before {
    content: $szgicon-lock;
  }
}
.szgicon-lock-open {
  &:before {
    content: $szgicon-lock-open;
  }
}
.szgicon-login {
  &:before {
    content: $szgicon-login;
  }
}
.szgicon-logout {
  &:before {
    content: $szgicon-logout;
  }
}
.szgicon-low-sense {
  &:before {
    content: $szgicon-low-sense;
  }
}
.szgicon-low-vision {
  &:before {
    content: $szgicon-low-vision;
  }
}
.szgicon-maker {
  &:before {
    content: $szgicon-maker;
  }
}
.szgicon-map-marker {
  &:before {
    content: $szgicon-map-marker;
  }
}
.szgicon-map-view {
  &:before {
    content: $szgicon-map-view;
  }
}
.szgicon-metro {
  &:before {
    content: $szgicon-metro;
  }
}
.szgicon-microwave {
  &:before {
    content: $szgicon-microwave;
  }
}
.szgicon-minibar {
  &:before {
    content: $szgicon-minibar;
  }
}
.szgicon-modern-building {
  &:before {
    content: $szgicon-modern-building;
  }
}
.szgicon-money-transfer {
  &:before {
    content: $szgicon-money-transfer;
  }
}
.szgicon-more-vert {
  &:before {
    content: $szgicon-more-vert;
  }
}
.szgicon-mosquitonets {
  &:before {
    content: $szgicon-mosquitonets;
  }
}
.szgicon-mountain {
  &:before {
    content: $szgicon-mountain;
  }
}
.szgicon-movie {
  &:before {
    content: $szgicon-movie;
  }
}
.szgicon-museum {
  &:before {
    content: $szgicon-museum;
  }
}
.szgicon-national-park {
  &:before {
    content: $szgicon-national-park;
  }
}
.szgicon-nature {
  &:before {
    content: $szgicon-nature;
  }
}
.szgicon-navigation {
  &:before {
    content: $szgicon-navigation;
  }
}
.szgicon-news {
  &:before {
    content: $szgicon-news;
  }
}
.szgicon-no-bedding {
  &:before {
    content: $szgicon-no-bedding;
  }
}
.szgicon-no-card {
  &:before {
    content: $szgicon-no-card;
  }
}
.szgicon-old-television {
  &:before {
    content: $szgicon-old-television;
  }
}
.szgicon-open-outside {
  &:before {
    content: $szgicon-open-outside;
  }
}
.szgicon-order-by-cheap {
  &:before {
    content: $szgicon-order-by-cheap;
  }
}
.szgicon-order-by-expensive {
  &:before {
    content: $szgicon-order-by-expensive;
  }
}
.szgicon-oven {
  &:before {
    content: $szgicon-oven;
  }
}
.szgicon-palm-tree {
  &:before {
    content: $szgicon-palm-tree;
  }
}
.szgicon-parking {
  &:before {
    content: $szgicon-parking;
  }
}
.szgicon-parking-car {
  &:before {
    content: $szgicon-parking-car;
  }
}
.szgicon-payment {
  &:before {
    content: $szgicon-payment;
  }
}
.szgicon-payment-guide {
  &:before {
    content: $szgicon-payment-guide;
  }
}
.szgicon-person {
  &:before {
    content: $szgicon-person;
  }
}
.szgicon-phone {
  &:before {
    content: $szgicon-phone;
  }
}
.szgicon-plane {
  &:before {
    content: $szgicon-plane;
  }
}
.szgicon-player {
  &:before {
    content: $szgicon-player;
  }
}
.szgicon-play-store {
  &:before {
    content: $szgicon-play-store;
  }
}
.szgicon-polaroid {
  &:before {
    content: $szgicon-polaroid;
  }
}
.szgicon-pool {
  &:before {
    content: $szgicon-pool;
  }
}
.szgicon-popup {
  &:before {
    content: $szgicon-popup;
  }
}
.szgicon-position {
  &:before {
    content: $szgicon-position;
  }
}
.szgicon-positions-two {
  &:before {
    content: $szgicon-positions-two;
  }
}
.szgicon-print {
  &:before {
    content: $szgicon-print;
  }
}
.szgicon-prize-game {
  &:before {
    content: $szgicon-prize-game;
  }
}
.szgicon-programs {
  &:before {
    content: $szgicon-programs;
  }
}
.szgicon-program-tourism {
  &:before {
    content: $szgicon-program-tourism;
  }
}
.szgicon-question-sign {
  &:before {
    content: $szgicon-question-sign;
  }
}
.szgicon-rankings {
  &:before {
    content: $szgicon-rankings;
  }
}
.szgicon-rating-type-family-with-little-child {
  &:before {
    content: $szgicon-rating-type-family-with-little-child;
  }
}
.szgicon-rating-type-family-with-older-child {
  &:before {
    content: $szgicon-rating-type-family-with-older-child;
  }
}
.szgicon-rating-type-group-of-friends {
  &:before {
    content: $szgicon-rating-type-group-of-friends;
  }
}
.szgicon-rating-type-middleaged-couple {
  &:before {
    content: $szgicon-rating-type-middleaged-couple;
  }
}
.szgicon-rating-type-old-couple {
  &:before {
    content: $szgicon-rating-type-old-couple;
  }
}
.szgicon-rating-type-single {
  &:before {
    content: $szgicon-rating-type-single;
  }
}
.szgicon-rating-type-work {
  &:before {
    content: $szgicon-rating-type-work;
  }
}
.szgicon-rating-type-young-couple {
  &:before {
    content: $szgicon-rating-type-young-couple;
  }
}
.szgicon-refresh {
  &:before {
    content: $szgicon-refresh;
  }
}
.szgicon-refrigerator {
  &:before {
    content: $szgicon-refrigerator;
  }
}
.szgicon-reply {
  &:before {
    content: $szgicon-reply;
  }
}
.szgicon-restaurant {
  &:before {
    content: $szgicon-restaurant;
  }
}
.szgicon-roof {
  &:before {
    content: $szgicon-roof;
  }
}
.szgicon-room-fan {
  &:before {
    content: $szgicon-room-fan;
  }
}
.szgicon-rotate-left {
  &:before {
    content: $szgicon-rotate-left;
  }
}
.szgicon-rotate-right {
  &:before {
    content: $szgicon-rotate-right;
  }
}
.szgicon-rowing {
  &:before {
    content: $szgicon-rowing;
  }
}
.szgicon-safe {
  &:before {
    content: $szgicon-safe;
  }
}
.szgicon-satellite-tv {
  &:before {
    content: $szgicon-satellite-tv;
  }
}
.szgicon-sauna {
  &:before {
    content: $szgicon-sauna;
  }
}
.szgicon-search {
  &:before {
    content: $szgicon-search;
  }
}
.szgicon-seo-stat {
  &:before {
    content: $szgicon-seo-stat;
  }
}
.szgicon-share-outline {
  &:before {
    content: $szgicon-share-outline;
  }
}
.szgicon-shield-yes {
  &:before {
    content: $szgicon-shield-yes;
  }
}
.szgicon-shine {
  &:before {
    content: $szgicon-shine;
  }
}
.szgicon-shopping {
  &:before {
    content: $szgicon-shopping;
  }
}
.szgicon-shoreline {
  &:before {
    content: $szgicon-shoreline;
  }
}
.szgicon-shower {
  &:before {
    content: $szgicon-shower;
  }
}
.szgicon-shutter {
  &:before {
    content: $szgicon-shutter;
  }
}
.szgicon-sights {
  &:before {
    content: $szgicon-sights;
  }
}
.szgicon-skiing {
  &:before {
    content: $szgicon-skiing;
  }
}
.szgicon-ski-lift-chair {
  &:before {
    content: $szgicon-ski-lift-chair;
  }
}
.szgicon-ski-lift-gondola {
  &:before {
    content: $szgicon-ski-lift-gondola;
  }
}
.szgicon-ski-lift-surface {
  &:before {
    content: $szgicon-ski-lift-surface;
  }
}
.szgicon-ski-lift-train {
  &:before {
    content: $szgicon-ski-lift-train;
  }
}
.szgicon-smack {
  &:before {
    content: $szgicon-smack;
  }
}
.szgicon-smart-phone {
  &:before {
    content: $szgicon-smart-phone;
  }
}
.szgicon-smart-tv {
  &:before {
    content: $szgicon-smart-tv;
  }
}
.szgicon-sms {
  &:before {
    content: $szgicon-sms;
  }
}
.szgicon-solar-panel {
  &:before {
    content: $szgicon-solar-panel;
  }
}
.szgicon-sort {
  &:before {
    content: $szgicon-sort;
  }
}
.szgicon-spa {
  &:before {
    content: $szgicon-spa;
  }
}
.szgicon-speakabout {
  &:before {
    content: $szgicon-speakabout;
  }
}
.szgicon-speech-bubble-text {
  &:before {
    content: $szgicon-speech-bubble-text;
  }
}
.szgicon-spoken-language {
  &:before {
    content: $szgicon-spoken-language;
  }
}
.szgicon-sport {
  &:before {
    content: $szgicon-sport;
  }
}
.szgicon-star {
  &:before {
    content: $szgicon-star;
  }
}
.szgicon-star-outlined {
  &:before {
    content: $szgicon-star-outlined;
  }
}
.szgicon-stars-four {
  &:before {
    content: $szgicon-stars-four;
  }
}
.szgicon-subitem {
  &:before {
    content: $szgicon-subitem;
  }
}
.szgicon-suggested-partner {
  &:before {
    content: $szgicon-suggested-partner;
  }
}
.szgicon-suitcase {
  &:before {
    content: $szgicon-suitcase;
  }
}
.szgicon-szep-card {
  &:before {
    content: $szgicon-szep-card;
  }
}
.szgicon-theme-park {
  &:before {
    content: $szgicon-theme-park;
  }
}
.szgicon-thumbs-down {
  &:before {
    content: $szgicon-thumbs-down;
  }
}
.szgicon-thumbs-up {
  &:before {
    content: $szgicon-thumbs-up;
  }
}
.szgicon-ticket {
  &:before {
    content: $szgicon-ticket;
  }
}
.szgicon-tiled-stove {
  &:before {
    content: $szgicon-tiled-stove;
  }
}
.szgicon-tiles {
  &:before {
    content: $szgicon-tiles;
  }
}
.szgicon-time {
  &:before {
    content: $szgicon-time;
  }
}
.szgicon-toys {
  &:before {
    content: $szgicon-toys;
  }
}
.szgicon-train {
  &:before {
    content: $szgicon-train;
  }
}
.szgicon-translate {
  &:before {
    content: $szgicon-translate;
  }
}
.szgicon-trash {
  &:before {
    content: $szgicon-trash;
  }
}
.szgicon-umbrella-rain {
  &:before {
    content: $szgicon-umbrella-rain;
  }
}
.szgicon-upload {
  &:before {
    content: $szgicon-upload;
  }
}
.szgicon-user {
  &:before {
    content: $szgicon-user;
  }
}
.szgicon-viber {
  &:before {
    content: $szgicon-viber;
  }
}
.szgicon-video {
  &:before {
    content: $szgicon-video;
  }
}
.szgicon-videogame {
  &:before {
    content: $szgicon-videogame;
  }
}
.szgicon-warning {
  &:before {
    content: $szgicon-warning;
  }
}
.szgicon-washing-machine {
  &:before {
    content: $szgicon-washing-machine;
  }
}
.szgicon-waterdrop-splash {
  &:before {
    content: $szgicon-waterdrop-splash;
  }
}
.szgicon-waterside {
  &:before {
    content: $szgicon-waterside;
  }
}
.szgicon-water-tank {
  &:before {
    content: $szgicon-water-tank;
  }
}
.szgicon-wc {
  &:before {
    content: $szgicon-wc;
  }
}
.szgicon-wifi {
  &:before {
    content: $szgicon-wifi;
  }
}
.szgicon-wine {
  &:before {
    content: $szgicon-wine;
  }
}
.szgicon-win-ticket {
  &:before {
    content: $szgicon-win-ticket;
  }
}
.szgicon-wired-internet {
  &:before {
    content: $szgicon-wired-internet;
  }
}
.szgicon-woman {
  &:before {
    content: $szgicon-woman;
  }
}
.szgicon-wooden-cubes {
  &:before {
    content: $szgicon-wooden-cubes;
  }
}
.szgicon-zoom-in {
  &:before {
    content: $szgicon-zoom-in;
  }
}

