
h1 {
    font-size: 36px;
    font-weight: 600;
    color: var(--text-color);

    &.title-shape {
        font-size: 25px;
        font-weight: 700;
        position: relative;
        display: inline-block;
        background: var(--primary-color);
        padding: 12px 20px;
        line-height: 1.2;
        color: var(--promotion-block-title-color, #fff);

        &::after {
            position: absolute;
            display: block;
            right: -12px;
            top: 0;
            width: 30px;
            height: 100%;
            content: " ";
            z-index: -1;
            background: var(--primary-color);
            transform: skewX(16deg);
        }

        @include media-breakpoint-down(sm) {
            padding: 8px 12px;
            font-size: 19px;
        }
    }
}

h2 {
    font-size: 26px;
    font-weight: 700;
    color: var(--text-color);

    a {
        text-decoration: none;
        color: var(--text-color);
    }

    @include media-breakpoint-down(sm) {
        font-size: 16px;
    }
}

a {
    color: var(--link-color);
    text-decoration: none;

    &.large-link {
        font-size: 22px;
        font-weight: 500;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }
    }
}
