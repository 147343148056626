.footer {
    position: relative;
    background: var(--footer-bg-color);
    padding-top: 60px;
    font-size: 14px;
    color: var(--text-color);
    overflow: hidden;
    z-index: 1;

    & >.container {
        padding-bottom: 25px;
    }

    img {
        max-width: 100%;
    }

    &__logo {
        margin-bottom: 15px;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;

        @include media-breakpoint-down(md) {
            font-size: 14px;
        }
    }

    ul {
        margin: 10px 0 0;
        padding: 0 0 0 20px;

        @include media-breakpoint-down(md) {
            padding: 0;

            li {
                list-style: none;
            }
        }
    }

    a {
        color: var(--text-color);
        text-decoration: underline;
    }

    &__object {
        position: absolute;
        right: 0;
        bottom: -40px;
        z-index: -1;
    }

    &__copyright {
        display: flex;
        align-items: center;
        height: 55px;
        background: var(--footer-copyright-bg-color);
        color: var(--text-color);
    }

    .social-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: var(--footer-social-bg-color);
        text-decoration: none;

        i {
            font-size: 18px;
        }
    }

    @include media-breakpoint-down(lg) {
        padding-top: 40px;
    }
}
