.category-card {
    position: relative;

    &__image {
        position: relative;
        max-width: 100%;
        overflow: hidden;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__badge {
        text-align: center;

        span {
            background: var(--primary-color);
            font-size: 18px;
            text-transform: uppercase;
            color: #fff;
            font-weight: 700;
            padding: 3px 10px;
            line-height: 1;

            @include media-breakpoint-down(md) {
                font-size: 12px;
            }
        }
    }

    &__title {
        font-size: 13px;
        font-weight: 700;
        color: var(--text-color);
        margin-top: 5px;

        @include media-breakpoint-down(md) {
            margin-top: 10px;
        }

        @include media-breakpoint-up(sm) {
            font-size: 18px;
        }
    }

    &--rounded {

        .category-card__image {
            margin-bottom: 20px;
        }

        .category-card__title {
            text-align: center;
        }

        .category-card__image {
            border-radius: 50%;
        }
    }
}
