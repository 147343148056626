.detail-search {
    position: relative;
    border: 2px solid #ECECEC;
    padding: 45px 60px;

    @include media-breakpoint-down(xxl) {
        padding: 45px 30px;
    }

    &__title {
        position: absolute;
        top: -20px;
        left: 45px;
        font-size: 26px;
        font-weight: 700;
        color: var(--text-color);
        background: #fff;
        padding: 0 5px;
    }

    &__buttons {
        display: flex;
        gap: 0 42px;

        .dropdown {
            flex: 1 1 auto;
        }

        @include media-breakpoint-down(xxl) {
            gap: 0 15px;
        }

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            gap: 15px 0;
        }
    }

    &__checkboxes {
        padding: 15px;
        border-bottom: 1px solid #AFAFAF;
        max-height: 380px;
        overflow-y: auto;

        .checkbox {
            display: flex;
            gap: 7px;
            align-items: center;
            margin-bottom: 10px;
            white-space: nowrap;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__action-btn {
        max-width: 170px;
    }

    .button-dropdown {

        > button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-color: #AFAFAF;
            border-radius: 4px;
            font-weight: 500;
            width: 100%;

            &::after {
                border: 0;
                font-family: szguruicons;
                content: $szgicon-chevron-down2;
                vertical-align: -2px;
                color: var(--primary-color);
            }
        }

        .dropdown-menu {
            border-color: #AFAFAF;
            padding: 0;
        }
    }

    &__select {
        padding: 12px;

        button {
            display: block;
            width: 100%;
            background: var(--button-primary-bg-color);
            color: var(--button-primary-color);
            height: 30px;
            border: 0;
            border-radius: 0 !important;

            &::after {
                display: none;
            }
        }
    }

    &--selected-button {
        background: #F6F6F6;

        &::after {
            position: absolute;
            top: -8px;
            left: -8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: szguruicons;
            content: $szgicon-checkmark;
            background: var(--button-primary-bg-color);
            color: #fff;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            line-height: 1;
        }
    }
}
