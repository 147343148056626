
@mixin hide-scrollbar {

    &::-webkit-scrollbar {
        display: none;
        width: 0;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
