.deal-box {
    position: relative;
    border: 1px solid var(--deal-box-border-color);
    background-color: #FFF;

    &__image {
        position: relative;
        aspect-ratio: 4/3;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__image-overlay {
        display: flex;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        overflow: hidden;
    }

    &__badge {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding: 0 15px;
        color: var(--deal-box-badge-text-color);
        white-space: nowrap;

        &:nth-child(2) {
            flex: 1;
        }

        &--blue {
            background: var(--deal-box-badge-blue);
        }

        &--red {
            background: var(--deal-box-badge-red);
        }

        @include media-breakpoint-down(xxl) {
            font-size: 14px;
            height: 35px;
        }

        @include media-breakpoint-down(sm) {
            height: 22px;
            font-size: 10px;
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        padding: 25px 20px 20px;
        height: 150px;
        box-shadow: 0 0 3px 2px rgb(150 150 150 / 20%);

        @include media-breakpoint-down(sm) {
            height: 120px;
            padding: 15px 12px;
        }
    }

    &__campaign {
        position: relative;
        top: -10px;
        font-size: 17px;
        font-weight: 500;
        color: var(--deal-box-badge-color);
        background: var(--deal-box-badge-background);
        padding: 3px 20px;

        @include media-breakpoint-down(sm) {
            font-size: 11px;
        }
    }

    &__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 20px;
        text-decoration: none;
        color: var(--text-color);
        font-weight: 700;
        line-height: 1.3;
        outline: none;

        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }

    &__place {
        margin-top: 5px;

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }

    &__deadline {
        margin-top: auto;
        font-size: 15px;

        strong {
            font-weight: 400;
        }

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }

    &__discount {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 12px;
        right: -12px;
        width: 60px;
        height: 60px;
        background: var(--deal-box-discount-background);
        color: var(--deal-box-discount-text-color);
        font-size: 24px;
        font-weight: 600;
        padding-right: 10px;
        z-index: 1;

        &::after {
            position: absolute;
            display: block;
            left: -12px;
            top: 0;
            width: 30px;
            height: 60px;
            content: ' ';
            z-index: -1;
            background: var(--deal-box-discount-background);
            transform: skewX(16deg);
        }

        @include media-breakpoint-down(sm) {
            width: 40px;
            height: 40px;
            font-size: 16px;

            &::after {
                height: 40px;
            }
        }
    }

    &__cta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        background: var(--deal-box-cta-bg-color);
        box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
        min-height: 90px;
        padding: 10px;

        @include media-breakpoint-down(sm) {
            min-height: 55px;
        }
    }

    &__cta-info {
        margin-top: 5px;
        color: var(--text-color);

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }

    &__prices {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 0 10px;
        color: var(--promotion-price-color, var(--primary-color));
        line-height: 1;
        white-space: nowrap;
    }

    &__price {
        font-size: 28px;
        font-weight: 800;

        @include media-breakpoint-down(xxl) {
            font-size: 26px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 19px;
        }
    }

    &__old-price {
        text-decoration: line-through;
        color: var(--primary-color);

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }

    &__exclusive {
        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }

    &__hotel-name {
        color: var(--link-color);
        font-weight: bold;
    }

    &__hotel-place {
        margin-top: 5px;
        font-size: 12px;
    }
}
