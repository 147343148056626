.mobile-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    width: 100%;
    z-index: 100;
    border-top: 1px solid #afafaf;
    border-bottom: 1px solid #afafaf;
    box-shadow: 0 3px 6px rgb(0 0 0 / 20%);

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none;
        padding: 0 10px;

        &:last-child a {
            border-bottom: 0;
        }
    }

    a {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        height: 35px;
        border-bottom: 1px solid #afafaf;
        color: #535353;
        text-decoration: none;
        padding-right: 10px;
    }

    &--active a {
        color: #222;
        border-right: 2px solid #bf392a;
        font-weight: 500;
    }

    &--open {
        display: block;
    }
}
