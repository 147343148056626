
.input {
    display: block;
    width: 100%;
    outline: none;
    height: 50px;
    padding: 0 15px;

    &--default {
        border: 2px solid var(--input-default-border-color);
        background: var(--input-default-bg-color);
        border-radius: 4px;
        transition: border-color 400ms;

        &:focus, &:active {
            border-color: var(--input-default-focus-border);
        }
    }

    &--invalid {
        border-color: #BF3A2A;

        & + .input-error {
            font-size: 12px;
            margin-top: 5px;
            color: #BF3A2A;
        }
    }

    @include media-breakpoint-down(sm) {
        height: 32px;
        font-size: 12px;
    }
}

.checkbox {
    position: relative;

    input[type="checkbox"] {
        appearance: none;
        -webkit-appearance: none;
        display: flex;
        align-content: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border: 2px solid var(--checkbox-border-color);
        border-radius: 4px;
        background: var(--checkbox-bg-color);
        overflow: hidden;
        flex: 0 0 auto;

        &.input--invalid {
            border-color: #BF3A2A;
        }
    }

    input[type="checkbox"]::before {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-family: szguruicons;
        content: $szgicon-checkmark;
        background-color: var(--checkbox-checked-bg-color);
        color: var(--checkbox-checked-icon-color);
        transform: scale(0);
        font-size: 12px;
        transition: transform 150ms ease;
        line-height: 1;
    }

    input[type="checkbox"]:checked::before {
        transform: scale(1);
    }
}


