.pagination {
    position: relative;

    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 0 20px;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;

        li {
            list-style: none;

            a {
                font-size: 18px;
                color: var(--text-color);

                &.active {
                    font-weight: 700;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 13px;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            gap: 0 15px;
        }
    }

    &__next, &__prev {

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            background: #f6f6f6;

            @include media-breakpoint-down(sm) {
                width: 18px;
                height: 18px;
            }
        }

        &.disabled a {
            opacity: .5;
            pointer-events: none;
        }

        i {
            font-size: 14px;

            @include media-breakpoint-down(sm) {
                font-size: 10px;
            }
        }
    }
}
