.button {
    position: relative;
    display: block;
    width: 100%;
    height: 50px;
    padding: 0 40px;
    outline: none;
    border: 0;

    &--primary {
        background: var(--button-primary-bg-color);
        color: var(--button-primary-color);
        font-size: 22px;
        font-weight: 700;
        transition: background-color 400ms ease;

        &:hover {
            background: var(--cta-hover-background-color, var(--button-primary-bg-color));
        }

        @include media-breakpoint-down(sm) {
            font-size: 13px;
        }
    }

    &--primary-outline {
        background: transparent;
        color: var(--button-primary-outline-color);
        border: 2px solid var(--button-primary-outline-color);
        font-size: 24px;
        font-weight: 500;
        padding: 0 15px;
        transition: border-color 400ms ease, color 400ms ease;

        &:hover {
            border-color: var(--cta-hover-background-color, var(--button-primary-bg-color));
            color: var(--cta-hover-background-color, var(--button-primary-bg-color));
        }
    }

    &--secondary {
        background: transparent;
        border: 1px solid #AFAFAF;
        color: #FF9900;
        border-radius: 3px;

        i {
            position: relative;
            top: 1px;
        }
    }

    @include media-breakpoint-down(sm) {
        height: 38px;
        font-size: 14px;
    }

    &--order {
        white-space: nowrap;
        text-align: center;
        line-height: 50px;

        @include media-breakpoint-down(sm) {
            line-height: 38px;
        }
    }
}

.button-dropdown {

    button {
        height: 50px;
        background: transparent;
        border: 1px solid #d9d9d9;
        font-size: 16px;
        font-weight: 700;
        color: var(--text-color);
        padding: 0 15px;
        outline: none;
    }

    span {
        i {
            font-size: 14px;
            color: var(--primary-color);
            padding: 0 1px;

            &.szgicon-arrow-up, &.szgicon-arrow-up {
                margin: 0 -5px;
            }
        }
    }

    .dropdown-menu {
        min-width: 220px;
        border-radius: 0;
        box-shadow: 0px 2px 3px rgba(0,0,0, .1);
        border-color: #d9d9d9;

        li {

            a {
                padding-left: 15px;
                padding-right: 15px;
                color: var(--text-color);
                font-size: 16px;

                i {
                    padding-right: 5px;
                    color: var(--primary-color);
                    opacity: 0;
                }

                &.active {
                    background: none;
                    color: var(--text-color);
                    font-weight: 700;

                    i {
                        opacity: 1;
                    }
                }

                &:hover {
                    background: none;
                }
            }
        }
    }
}
