.badge {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 12px;
    padding: 2px 5px;
    cursor: default;
    gap: 0 5px;

    &--filter {
        background: var(--badge-filter-background);
        color: var(--badge-filter-color);
    }

    a {
        color: var(--badge-filter-link-color);
        text-decoration: none;
        font-size: 10px;
        font-weight: 700;
    }
}
