.header {
    position: relative;
    background: #fff;

    @include media-breakpoint-down(lg) {
        padding: 10px 0;
    }

    &__logo {

        @include media-breakpoint-down(lg) {
            height: 16px;

            &-christmas {
                height: 22px;
            }
        }

        @include media-breakpoint-up(md) {
            &-christmas {
                width: 250px;
            }
        }
    }

    &__top {
        position: relative;
        display: flex;
        gap: 0 110px;
        align-items: center;
        background: var(--header-top-bg);
        min-height: 80px;

        @include media-breakpoint-down(xxl) {
            gap: 0 30px;
        }

        @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
            min-height: 33px;
        }
    }

    &__top-search {
        flex: auto;

        &-mobile-close {
            display: none;
            align-items: center;

            @include media-breakpoint-down(lg) {
                display: flex;
            }
        }

        @include media-breakpoint-down(lg) {
            display: none;
            position: absolute;
            left: calc(var(--bs-gutter-x) * 0.5 * -1);
            width: calc(100% + var(--bs-gutter-x));
            top: 100%;
            background: var(--header-top-bg);
            padding: 10px;
            order: 3;
            flex: 100%;
            gap: 0 10px;
        }
    }

    .header-search {
        position: relative;
        flex: 1 1 auto;

        &__input {
            display: block;
            width: 100%;
            border: 1px solid var(--header-search-border);
            border-radius: 8px;
            height: 38px;
            padding: 0 40px 0 15px;
            outline: none;
            font-size: 16px;

            &:focus, &:active {
                border-color: #3598db;

                + button {
                    color: #3598db;
                }
            }

            @include media-breakpoint-down(lg) {
                height: 32px;
                font-size: 12px;
                padding-right: 0;
            }
        }

        &__btn {
            display: flex;
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
            background: none;
            border: 0;

            i {
                font-size: 24px;

                @include media-breakpoint-down(lg) {
                    font-size: 22px;
                }
            }

            @include media-breakpoint-down(lg) {
                display: flex;
                align-items: center;
                height: 32px;
                right: 0;
                background: var(--primary-color);
                color: #fff;
                border-radius: 8px;
            }
        }
    }

    &__top-right {
        margin-left: auto;

        @include media-breakpoint-down(lg) {
            margin-left: auto;
        }

        ul {
            display: flex;
            margin: 0;
            padding: 0;
            gap: 0 30px;

            @include media-breakpoint-down(lg) {
                gap: 0 15px;
            }

            li {
                display: flex;
                align-items: center;
                list-style: none;
                color: var(--text-color);
                white-space: nowrap;
                gap: 5px;

                a {
                    color: var(--text-color);
                    text-decoration: none;
                }

                i {
                    color: var(--menu-bg-color);
                    font-size: 20px;
                }
            }
        }
    }
}
