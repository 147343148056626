.destination-card {
    position: relative;
    overflow: hidden;

    img {
        width: 100%;

        @include media-breakpoint-down(lg) {
            aspect-ratio: 1/1;
            object-fit: cover;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        bottom: 30px;
        font-size: 26px;
        font-weight: 500;
        color: var(--text-color);
        width: 310px;
        padding-left: 25px;
        height: 66px;
        z-index: 1;

        @include media-breakpoint-down(xl) {
            height: 50px;
            font-size: 22px;
            width: 280px;
        }

        @include media-breakpoint-down(lg) {
            position: relative;
            bottom: 0;
            height: auto;
            padding-left: 0;
            margin-top: 5px;
            font-weight: 700;
            font-size: 18px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 13px;
        }

        &::after {
            display: block;
            position: absolute;
            top: 0;
            right: 11px;
            content: ' ';
            width: 100%;
            height: 100%;
            background: var(--destination-card-title-bg-color);
            transform: skewX(18deg);
            z-index: -1;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }

    .stretched-link::after {
        z-index: 2;
    }
}
